import {
  TablePageIndexDefaultConst,
  TablePageSizeDefaultConst,
} from "../const";

export class TableConfigModel {
  public dataKey: string[];
  public dataLabel: string[];
  public dataSortKey: (string | null)[];
  public dataSource: any[];
  public dataTotal: number;
  public dataType: { [x: string]: string };
  public pageIndex: number;
  public pageSize: number;
  public sortKey?: string;
  public sortOrder: "asc" | "desc" | "";

  constructor() {
    this.dataKey = [];
    this.dataLabel = [];
    this.dataSortKey = [];
    this.dataSource = [];
    this.dataTotal = 0;
    this.dataType = {};
    this.pageIndex = TablePageIndexDefaultConst;
    this.pageSize = TablePageSizeDefaultConst;
    this.sortOrder = "asc";
  }

  public generateDataType() {
    if (this.dataSource) {
      for (const key in this.dataSource[0]) {
        const type = typeof this.dataSource[0][key];

        this.dataType[key] =
          this.dataSource[0][key] instanceof Date ? "date" : type;
      }
    }

    return this;
  }

  public isEmpty(): boolean {
    return this.dataSource?.length === 0;
  }

  public resetPage() {
    this.pageIndex = TablePageIndexDefaultConst;
    this.pageSize = TablePageSizeDefaultConst;
    this.sortKey = undefined;
    this.sortOrder = "asc";
  }

  public resetDataSource() {
    this.resetPage();

    this.dataSource = [];
    this.dataTotal = 0;
  }
}
